
.card-div{
  border: 1px solid #dadce0;
  border-radius: 8px;
  max-width: 640px;
  width: 640px;
  background-color: white;
}

.required{
  //font-size: 14px;
  color: #d93025;
}

.input-form{
  //margin-top: 12px !important;
}

.body-container{
    display: flex;
    flex-direction: column;
    padding: 12px 0px;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    .header-logos{
      height: 159.5px;
      margin: 0 auto 12px auto;
      //background-image: url("../../assets/header.jpeg");
      //background-size: cover;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .title-form{
      //padding: 10px;
      margin: 12px auto;
      background-color: white;
      .title-form-card-div-container{
        padding: 20px;
        display: flex;
        flex-direction: column;
        .title{
          font-size: 24pt;
          font-weight: 540;
          font-family: 'docs-Roboto', Helvetica, Arial, sans-serif;
          letter-spacing: 0;
          line-height: 135%;
          //color: #202124;
        }
        .advertising{
          font-size: 11pt;
          margin-top: 12px;
        }
        .obligatorio{
          color: #d93025;
          font-size: 14px;
          //margin-top: 12px;
        }
      }
    }
    .estado-div{
      margin: 12px auto;
      .estado-div-container{
        padding: 20px;
        display: flex;
        flex-direction: column;
        font-size: 12pt;
      }
    }
    .actividad-propuesta-div{
      margin: 12px auto;
      .actividad-propuesta-div-container{
        padding: 20px;
      }
    }
    .nombre-actividad-div{
      margin: 12px auto;
      .nombre-actividad-div-container{
        padding: 20px;
        display: flex;
        flex-direction: column;
        .field{
          font-size: 12pt;
          margin-bottom: 12px;
        }
      }
    }

    .buttons-div{
      margin: 12px auto;
      width: 640px;
      .buttons-div-container{
        display: flex;
        justify-content: space-between;
        .buttons-action{
          display: flex;
        }
      }
    }
  }