.super-container{
  display: flex;
  width: 100vw;
  height: 100vh;
  .side-container{
    display: flex; 
    flex-direction: column;
    width: 200px;
    background-color: #5b5d5e;//1828c8 //8d2c3f
    min-width: 200px;
    max-width: 200px;
    .logo-container{
      display: flex;
      justify-content: space-around;
      width: 100%;
      background-color: white;
      img{
        //width: 100%;
        margin: 0px 5px;
      }
    }
    .list-btns-side{
      .btn-side{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 10px 10px 24px;
        min-height: 40px;
        margin-bottom: 5px;
        cursor: pointer;
        span{
          transition: all 0.3s;
          color: hsla(0,0%,100%,.80);
        }
        &__activo{
          background-color: #808080;
          
          span{
            color: white;
          }
        }
        &:hover{
          
          span{
            color: white;
          }
        }
        .anticon{
          margin-right: 10px;
        }
      }
    }
    
  }
  
  .principal-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    .table-container{
      height: calc(100vh - 32px);
      .refresh-button{
        //background-color: white;
        //margin-top: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .footer{
      text-align: center;
      padding: 5px 50px;  
      color: rgba(0,0,0,.85);
      font-size: 14px;
      background: #f0f2f5;
    }
  }
}