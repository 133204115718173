.login-container{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .card-login{
    border: 1px solid #dce5f0;
    border-radius: 10px;
    width: 350px;
    .card-login-header {
      border-radius: 10px 10px 0 0;
      background-color: #45c7d0;//45c7d0
      padding: 5px 20px;
      h1{
        margin: 0px;
        color: white;
      }
    }
    .card-login-body {
      padding: 20px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-radius: 0 0 10px 10px;
      button{
        background-color: #45c7d0;//45c7d0
        border: none;
        width: 200px;
        border-radius: 10px;
        color: #fff;
        padding: 10px;
        display: block;
        margin:  auto;
        cursor: pointer;
      }
    }
  }
}
