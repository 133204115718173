.input-container{
  display: flex;
  flex-direction: column; 
  width: 100%;
  label{
    margin-bottom: 5px; 
    font-family: "Montserrat","Roboto",sans-serif; 
  }
  .ant-input{
    width: 100%;
  }
  .selection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px; 
  }
}